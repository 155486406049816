import i18n from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';
import ruYaml from './assets/locales/ru/translation.yaml';
import enYaml from './assets/locales/en/translation.yaml';
import fanzillaYaml from './assets/custom_locales/fanzilla/translation.yaml';
import shinnikYaml from './assets/custom_locales/shinnik/translation.yaml';
import chernomorochkaYaml from './assets/custom_locales/chernomorochka/translation.yaml';
import cskaMenYaml from './assets/custom_locales/cska-m/translation.yaml';
import cskaWomenYaml from './assets/custom_locales/cska-w/translation.yaml';
import locoYaml from './assets/custom_locales/loco/translation.yaml';
import { getRequiredEnv } from '@sebbia/dynamic-env';

const currentClub = getRequiredEnv('REACT_APP_CLUB');
const domain = getRequiredEnv('REACT_APP_DOMAIN');

function defineClubLanguage(clubId?: string) {
  let clubData = {
    mergedClubLocale: {},
    fallbackLng: '',
  };

  switch (clubId) {
    case 'fanzilla':
      clubData.mergedClubLocale = merge(fanzillaYaml, ruYaml, enYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'shinnik':
      clubData.mergedClubLocale = merge(shinnikYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'chernomorochka':
      clubData.mergedClubLocale = merge(chernomorochkaYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'loco':
      clubData.mergedClubLocale = merge(locoYaml, ruYaml, enYaml);
      clubData.fallbackLng = 'en';
      break;
    case 'cska-m':
      clubData.mergedClubLocale = merge(cskaMenYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    case 'cska-w':
      clubData.mergedClubLocale = merge(cskaWomenYaml, ruYaml);
      clubData.fallbackLng = 'ru';
      break;
    default:
      break;
  }

  return clubData;
}

const clubLanguage = defineClubLanguage(currentClub);

function definePriorityLanguage() {
  return getCookie('lng') ?? clubLanguage.fallbackLng;
}

function languagesByPriority() {
  const priotityLng = definePriorityLanguage();
  const anotherLngs = Object.keys(clubLanguage.mergedClubLocale).filter((lng) => lng !== priotityLng);
  return [priotityLng, ...anotherLngs];
}

const currentLngs = languagesByPriority();

export function getSupportedLngs() {
  return currentLngs;
}

function createCookie(key: string, value: string, domain?: string) {
  let cookie_date = new Date();
  cookie_date.setFullYear(cookie_date.getFullYear() + 1);
  domain
    ? (document.cookie = `${key}=${value};domain=${domain};path=/;expires=` + cookie_date.toUTCString())
    : (document.cookie = `${key}=${value};expires=` + cookie_date.toUTCString());
}

function deleteCookie(name: string) {
  document.cookie = `${name}=;max-age=-1`;
}

function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function switchLng(lng: string) {
  deleteCookie('lng');
  createCookie('lng', lng, domain);
  i18n.changeLanguage(lng);
}

i18n.use(initReactI18next).init({
  supportedLngs: currentLngs,
  fallbackLng: clubLanguage.fallbackLng ?? 'ru',
  lng: getCookie('lng') ?? clubLanguage.fallbackLng,
  resources: clubLanguage.mergedClubLocale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
